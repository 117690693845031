.tw-banner {
    background-color: #8b0000;
    text-align: center;
    align-content: center;
    padding: 3rem 1rem;
    color: white;
}

.tw-width {
    width: 900px;
}

@media only screen and (max-width: 600px) {
    .tw-width {
        width: 80%;
    }

    .table-responsive-lg {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    .tw-width {
        width: 900px;
    }

    .table-responsive-sm {
        display: none;
    }
}

a {
    /* color: (#8b0000, var(#8b0000, 1)); */
    color: #8b0000;
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline !important;
}